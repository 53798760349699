<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab>
        <template #title>
          <feather-icon icon="UploadIcon" />
          Toplu Aktarım
        </template>
        <insurance-call-excel-import />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import InsuranceCallExcelImport from '@/views/Crm/InsuranceCall/Components/Excel.vue'

export default {
  name: 'AddPage',
  components: {
    InsuranceCallExcelImport,
    BTab,
    BTabs,
  },
  data() {
    return {

    }
  },
  computed: {

  },
  watch: {

  },
  created() {

  },
  methods: {

  },
}
</script>
